import type User from "../../../dataType/user";
import type Game from "../../../dataType/game";
import classes from "../../teamView/teamView.module.css";
import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Avatar} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import styles from "./gamePage.module.css";
import ScoreManager from "../../scoreManager/scoreManager";
import GameOverviewQuali from "../../gameOverview/gameOverviewQuali";
import GameOverview from "../../gameOverview/gameOverview";
import ScoreManagerQuali from "../../scoreManager/scoreManagerQuali";

interface GamePageProps {
    game: Game;
    users: [User];
    cUser: User;
    onClickUser: (user: User) => void;
    setGames: (games: [Game]) => void;
}

const baseIMG = "https://scontent.xx.fbcdn.net/v/t1.15752-9/48413865_1005940086262445_5551458711429447680_n.png?stp=dst-png_p100x100&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=d74pei6lZzIQ7kNvgFuvZ5O&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_Q7cD1QHgvhuACOVcC8GJd2xfH1k7HQ98MzO4Xu7Ua95c8iLGLA&oe=66859653";
const cols = ["Joueur", "Pari"];
const labels = ["1", "X", "2"];
const qLabels = ["1", "2"];

const GamePage: React.FC<GamePageProps> = ({game, users, cUser, onClickUser, setGames}) => {
    const [openChange, setOpenChange] = React.useState(false);

    const center = () => {
        if (!game.result) {
            return <div>
                <h2>En cours</h2>
            </div>
        } else {
            return <div>
                <h2>{game.score[0] + "-" + game.score[1]}</h2>
                {
                    game.drawAt90 ?
                        game.score[0] === game.score[1] ?
                            game.qResult === "1" ?
                                <p style={{margin: '0.5em 0'}}>{game.teamA.name} gagnant aux pénaltys</p> :
                                <p style={{margin: '0.5em 0'}}>{game.teamB.name} gagnant aux pénaltys</p> :
                            <p style={{margin: '0.5em 0'}}>Match nul à 90'</p> : null}
            </div>
        }
    }

    function setStyle(label) {
        if (!game.result) {
            return `${styles.commonButton} ${styles.running}`
        } else {
            if (game.result === label) {
                return `${styles.commonButton} ${styles.success}`
            } else {
                return `${styles.commonButton} ${styles.lost}`
            }
        }
    }

    function setQStyle(label) {
        if (!game.qResult) {
            return `${styles.commonButton} ${styles.running}`
        } else {
            if (game.qResult === label) {
                return `${styles.commonButton} ${styles.success}`
            } else {
                return `${styles.commonButton} ${styles.lost}`
            }
        }
    }


    function CustomGameDisplay(user) {
        const bet = user.matchHistory.find(match => match[0] === game.id);
        const qBet = user.qualifyHistory.find(match => match[0] === game.id);

        return (
            <div className={styles.verticalFlex}>
                <ul className={styles.list}>
                    {bet &&
                        labels.map((label, index) => (
                            label === bet[1] ?
                                <li className={setStyle(label)}>
                                    {bet[2].toFixed(2)}
                                </li> :
                                <li className={`${styles.commonButton} ${styles.radioButton}`}>
                                    {game.odds[index].toFixed(2)}
                                </li>
                        ))
                    }
                </ul>
                <ul className={styles.list}>
                    {qBet &&
                            qLabels.map((label, index) => (
                                label === qBet[1] ?
                                    <li className={setQStyle(label)}>
                                        {qBet[2].toFixed(2)}
                                    </li> :
                                    <li className={`${styles.commonButton} ${styles.radioButton}`}>
                                        {game.qualifyOdd[index].toFixed(2)}
                                    </li>
                            ))
                        }
                </ul>
            </div>
        )
    }


    let sortedUsers = users ? [...users].sort((a, b) => b.points - a.points) : [];

    return (
        <div>
            <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className={classes.team}>
                        <span>{game.teamA.name}</span>
                        <img className={classes.flag} src={`https://flagcdn.com/${game.teamA.countryCode}.svg`}
                             width="50"
                             alt={`${game.teamA.name}`}/>
                    </div>

                    {(cUser ? (cUser.id === 1 ?
                            <button className={styles.button} onClick={() => setOpenChange(!openChange)}>
                                {center()}
                            </button> :
                            <div>
                                {center()}
                            </div>) :
                        <div>
                            {center()}
                        </div>)
                    }
                    <div className={classes.team}>
                        <span>{game.teamB.name}</span>
                        <img className={classes.flag} src={`https://flagcdn.com/${game.teamB.countryCode}.svg`}
                             width="50"
                             alt={`${game.teamB.name}`}/>
                    </div>

                </div>
            </div>
            {openChange ? game.stage ?
                    <ScoreManagerQuali game={game} setGames={setGames}/> :
                    <ScoreManager game={game} setGames={setGames}/>
                : null}
            <div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow key={"head"} className={styles.row}>
                                {cols.map((col) => (
                                    <TableCell key={"head-" + col} align="center">{col}</TableCell>))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedUsers.map((user, index) => (
                                <TableRow className={styles.row}
                                          key={user.username}
                                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell align={"center"}>
                                        <button className={styles.button} onClick={() => onClickUser(user)}>
                                            <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                                                <Avatar variant="circular" alt="candice"
                                                        src={user.picture ? user.picture : baseIMG}/>
                                                {user.username}
                                            </div>
                                        </button>
                                    </TableCell>
                                    <TableCell align="center">
                                        {CustomGameDisplay(user)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default GamePage;
