import Game, {getGameByTeams, getGamesMaxId} from "../dataType/game";
import Team from "../dataType/teamData";
import {useEffect, useState} from "react";

let url = "https://eu-offering-api.kambicdn.com/offering/v2018/bnlbe/listView/football/euro_2024/all/all/matches.json?lang=fr_BE&market=BE"
let singleURL = "https://production-superbet-offer-ng-be.freetls.fastly.net/v2/fr-BE/events/"

export function makeRequest() {
    return fetch(url)
        .then(response => response.json())
        .then(data => {
            return data['events']
        });
}


export function makeSingleMatchRequest(id) {
    let matchURL = singleURL + id
    return fetch(matchURL)
        .then(response => response.json())
        .then(data => {
            console.log(data['data']['odds'].length)
            return data['data']['odds'];
        });
}

