import {Game, getGameById} from "./game";
import type {SpecialBets} from "../components/specialBets/specialBets";

export class User {
    username: string
    matchHistory: [[number, string, number]]
    qualifyHistory: [[number, string, number]]
    specialBets: [[number, string, number]]
    points: number
    picture: string
    id: number

    constructor(username, points, id) {
        this.username = username;
        this.matchHistory = [];
        this.qualifyHistory = [];
        this.specialBets = [];
        this.points = points;
        this.picture = "";
        this.id = id;
    }


    addPoints(points: number) {
        this.points += points;
    }

    setPicture(picture: string) {
        this.picture = picture;
    }

    toString() {
        return this.username + " " + this.points + " " + this.id + " " + this.matchHistory + " " + this.picture;
    }

}


export function userAddGame(user: User, gameId: number, result: string, odd: number): void {
    let singleGame = user.matchHistory.find(game => game[0] === gameId);
    if (singleGame !== undefined) {
        singleGame[1] = result;
        singleGame[2] = odd;
        return;
    }
    user.matchHistory.push([gameId, result, odd]);
}

export function userAddQGame(user: User, gameId: number, result: string, odd: number, qResult:string, qOdd:number): void {
    let foundGame = false;
    let foundQGame = false;

    let singleGame = user.matchHistory.find(game => game[0] === gameId);
    if (singleGame !== undefined) {
        singleGame[1] = result;
        singleGame[2] = odd;
        foundGame = true;
    }
    let singleQGame = user.qualifyHistory.find(game => game[0] === gameId);
    if (singleQGame !== undefined) {
        singleQGame[1] = qResult;
        singleQGame[2] = qOdd;
        foundQGame = true;
    }
    if (!foundGame) user.matchHistory.push([gameId, result, odd]);
    if (!foundQGame) user.qualifyHistory.push([gameId, qResult, qOdd]);
}

export function userAddSpecialBet(user: User, specialBetId: number, result: string, odd: number): void {
    let singleBet = user.specialBets.find(bet => bet[0] === specialBetId);
    if (singleBet !== undefined) {
        singleBet[1] = result;
        singleBet[2] = odd;
        return;
    }
    user.specialBets.push([specialBetId, result, odd]);
}


export function userFindMatchBet(user: User, gameId: number) {
    if (!user.matchHistory) user.matchHistory = [];
    if (user.matchHistory.length === 0) return null;
    return user.matchHistory.find(bet => bet[0] === gameId);
}

export function userFindQualifyBet(user: User, gameId: number) {
    if (!user.qualifyHistory) user.qualifyHistory = [];
    if (user.qualifyHistory.length === 0) return null;
    return user.qualifyHistory.find(bet => bet[0] === gameId);
}

export function userFindSpecialBet(user: User, specialBetId: number) {
    if (user.specialBets.length === 0) return null;
    return user.specialBets.find(bet => bet[0] === specialBetId);
}


export function saveUserChanges(user: User): void {
    let users = getUsersList();
    let userIndex = users.findIndex(u => u.id === user.id);
    users[userIndex] = user;
    saveUsersList(users);
}


export function createUserList(): void {
    let users = [];
    users.push(new User("Candice", 100, 1));
    users.push(new User("Brenda", 200, 2));
    users.push(new User("Alice", 300, 3));

    localStorage.setItem('users', JSON.stringify(users));
}

export function saveUsersList(userList: [User]): void {
    localStorage.setItem('users', JSON.stringify(userList));
}

export function getUsersList(): [User] {
    return JSON.parse(localStorage.getItem('users'));
}


export function getUserById(users:[User],id: number): User {
    return users.find(user => user.id === id);
}

export function getUsersMaxId(users:[User]): number {
    if (users === null) return 0;
    return users.reduce((acc, user) => {
        return Math.max(acc, user.id);
    }, 0);
}

export function createNewUser(users:[User],username: string, picture: string): User {
    let maxId = getUsersMaxId(users);
    let newUser = new User(username, 0, maxId + 1);
    if (picture !== "") newUser.setPicture(picture);
    if (users === null) users = [];
    users.push(newUser);

    saveUsersList(users);
    return newUser;
}

export function createUserFromDict(dict): User {
    let user = new User(dict.username, dict.points, dict.id);
    user.matchHistory = dict.matchHistory;
    if (dict.qualifyHistory !== undefined) user.qualifyHistory = dict.qualifyHistory;
    user.specialBets = dict.specialBets;
    user.picture = dict.picture;
    return user;
}

export function updateUsersPoints(users:[User], games:[Game], specialBets:[SpecialBets]): [User] {
    if (!users) return null;
    if (!games) return users;
    if (!specialBets) return users;
    for (const user of users) {
        let points = 0;
        for (let i = 0; i < user.matchHistory.length; i++) {
            if(!user.matchHistory[i]) continue;
            let currentGame = getGameById(games, user.matchHistory[i][0]);
            if (!currentGame) continue;
            if (currentGame.result === "") continue;
            if (user.matchHistory[i][1] === currentGame.result) {
                points += user.matchHistory[i][2];
            }
        }
        if (user.qualifyHistory.length !== 0) {
            for (let i = 0; i < user.qualifyHistory.length; i++) {
                if(!user.qualifyHistory[i]) continue;
                let currentGame = getGameById(games, user.qualifyHistory[i][0]);
                if (!currentGame) continue;
                if (currentGame.qResult === "") continue;
                if (user.qualifyHistory[i][1] === currentGame.qResult) {
                    points += user.qualifyHistory[i][2];
                }
            }
        }
        if (user.specialBets.length !== 0) {
            for (let i = 0; i < user.specialBets.length; i++) {
                let currentSpecialBet = specialBets.find(bet => bet.id === user.specialBets[i][0]);
                if (!currentSpecialBet) continue;
                if (currentSpecialBet.result === "") continue;
                if (user.specialBets[i][1] === currentSpecialBet.result) {
                    points += user.specialBets[i][2];
                }
            }
        }
        user.points = points.toFixed(2);
    }



    return users;
}


export default User;
