import React from "react";
import styles from "./userPage.module.css";
import GameList from "../../gameList/GameList"; // Import your GameList component
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Avatar} from "@mui/material";
import Paper from "@mui/material/Paper";
import {User, getUserById} from "../../../dataType/user";
import {Game, getGameById} from "../../../dataType/game";
import CreateUserBar from "../createUserBar/createUserBar";

interface UserPageProps {
    cUser: User;
    userId: number;
    games: [Game];
    users: [User];
    setCurrentUser: (user: User) => void;
    setUsers: (users: [User]) => void;
    setCUser: (user: User) => void;
    onClickGroup: (game: Game) => void;
    onClickGame: (game: Game) => void;
}

const baseIMG = "https://scontent.xx.fbcdn.net/v/t1.15752-9/48413865_1005940086262445_5551458711429447680_n.png?stp=dst-png_p100x100&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=d74pei6lZzIQ7kNvgFuvZ5O&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_Q7cD1QHgvhuACOVcC8GJd2xfH1k7HQ98MzO4Xu7Ua95c8iLGLA&oe=66859653";


const UserPage: React.FC<UserPageProps> = ({
                                               cUser,
                                               userId,
                                               games,
                                               users,
                                               setCurrentUser,
                                               setUsers,
                                               setCUser,
                                               onClickGroup,
                                               onClickGame
                                           }) => {
    // console.log(userId);
    let user = getUserById(users, userId);

    const userModify = cUser ? cUser.id === userId : false;

    let gameCount = 0;
    let wins = 0;
    let points = 0;

    for (let i = 0; i < user.matchHistory.length; i++) {
        let currentGame = getGameById(games, user.matchHistory[i][0]);
        if (!currentGame) continue;
        if (currentGame.result !== "") gameCount++;
        if (user.matchHistory[i][1] === currentGame.result) {
            wins++;
            points += user.matchHistory[i][2];
        }
    }

    for (let i = 0; i < user.qualifyHistory.length; i++) {
        let currentGame = getGameById(games, user.qualifyHistory[i][0]);
        if (!currentGame) continue;
        if (currentGame.qResult !== "") gameCount++;
        if (user.qualifyHistory[i][1] === currentGame.qResult) {
            wins++;
            points += user.qualifyHistory[i][2];
        }
    }

    points = points.toFixed(2);
    let losses = gameCount - wins;

    let pastGames = games.filter((game) => {
        return game.result !== ""
    });

    return (
        <div className={Paper}>
            {userModify ? <CreateUserBar cUser={cUser} setCreateUserMode={() => {
                }} setUsers={setUsers} setCUser={setCurrentUser} users={users} newUserMode={false}/>
                : null}
            <div className={styles.userInfo}>
                <Avatar variant="circular" alt={user.username}
                        src={user.picture ? user.picture : baseIMG}/>
                <h2>{user.username}</h2>
                {/*<p>{points} points</p>*/}
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Matches</TableCell>
                        <TableCell>Victoires</TableCell>
                        <TableCell>Défaites</TableCell>
                        <TableCell>Points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{gameCount}</TableCell>
                        <TableCell>{wins}</TableCell>
                        <TableCell>{losses}</TableCell>
                        <TableCell>{points}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {(user.matchHistory.length > 0) ?
                <div>
                    <h2>Historique des matchs</h2>
                    <GameList games={pastGames} users={users} cUser={user}
                              onClickGroup={onClickGroup} setGames={() => {
                    }} onClickGame={onClickGame} setUsers={() => {
                    }}
                              setCUser={setCUser} inverted={true}/>
                </div> : null}

        </div>
    );
}

export default UserPage;
