import React from 'react';
import Game from '../../dataType/game';
import GameOverview from "../gameOverview/gameOverview"; // Import your Game type
import styles from './gameList.module.css';
import type User from "../../dataType/user";
import GameOverviewQuali from "../gameOverview/gameOverviewQuali";

interface GameListProps {
    games: Game[];
    cUser: User;
    onClickGroup: (game: Game) => void;
    onClickGame: (game: Game) => void;
    setGames: (games: Game[]) => void;
    setUsers: (users: User[]) => void;
    setCUser: (user: User) => void;
    inverted?: boolean;
}


const GameList: React.FC<GameListProps> = ({
                                               games,
                                               cUser,
                                               onClickGroup,
                                               onClickGame,
                                               setGames,
                                               setUsers,
                                               setCUser,
                                               inverted
                                           }) => {
    if (!games) return null;
    const sortedGames = games.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    if (inverted) sortedGames.reverse();

    return (
        <div className={styles.gameList}>
            {sortedGames.map((game) => (
                game ?
                    game.stage ?
                        <GameOverviewQuali key={game.id} game={game} cUser={cUser} onClickGroup={onClickGroup}
                                           onClickGame={onClickGame}
                                           setGames={setGames} setUsers={setUsers} setCUser={setCUser}/> :
                        <GameOverview key={game.id} game={game} cUser={cUser} onClickGroup={onClickGroup}
                                      onClickGame={onClickGame}
                                      setGames={setGames} setUsers={setUsers} setCUser={setCUser}/>
                    : null
            ))}
        </div>
    );
};
export default GameList;
