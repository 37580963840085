import React, {useEffect} from "react";
import styles from "./homePage.module.css";
import GameList from "../../gameList/GameList";
import Leaderboard from "../../leaderBoard/leaderBoard";
import type User from "../../../dataType/user";
import type Game from "../../../dataType/game";
import {SpecialBetList, SpecialBets} from "../../specialBets/specialBets";
import {uploadSpecialBets} from "../../../utilFiles/fireBaseStorage";

interface HomePageProps {
    games: [Game];
    users: [User];
    cUser: User;
    onClickUser: (user: User) => void;
    onClickGroup: (game: Game) => void;
    onclickGame: (game: Game) => void;
    setGames: (games: [Game]) => void;
    setUsers: (users: User[]) => void;
    specialBets: [SpecialBets];
    setSpecialBets: (specialBets: [SpecialBets]) => void;
    setCUser: (user: User) => void;
}


const HomePage: React.FC<HomePageProps> = ({
                                               games,
                                               users,
                                               cUser,
                                               onClickUser,
                                               onClickGroup,
                                               onclickGame,
                                               setGames,
                                               setUsers,
                                               specialBets,
                                               setSpecialBets, setCUser
                                           }) => {

    const [betType, setBetType] = React.useState('upcoming');
    const [updatedSpBet, setUpdatedSpBet] = React.useState(false);

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let todaysGames = games.filter((game) => {
        let gameDate = new Date(game.startTime);
        gameDate.setHours(0, 0, 0, 0);
        return gameDate.getTime() === today.getTime();
    });


    function handlePageChange(pageName: string) {
        if (betType === 'special' && updatedSpBet) {
            uploadSpecialBets(specialBets).then(() => {
                setUpdatedSpBet(false);
                console.log("Special bets uploaded")
            });
        }
        setBetType(pageName);
    }

    let previousGames = games.filter((game) => {return game.result !== ""})
    let upcomingGames = games.filter((game) => {return game.result === ""})

    return (
        <div className={styles.page}>
            <div className={styles.games}>
                <div>
                    <button onClick={() => handlePageChange('today')}>
                        Matchs du jour
                    </button>
                    <button onClick={() => handlePageChange('upcoming')}>
                        Prochains matchs
                    </button>
                    <button onClick={() => handlePageChange('special')}>
                        Paris spéciaux
                    </button>
                    <button onClick={() => handlePageChange('previous')}>
                        Anciens matchs
                    </button>
                </div>
                {betType === 'today' ? <div>
                        <h2>Matchs du jour</h2>
                        <GameList games={todaysGames} cUser={cUser} onClickGame={onclickGame} onClickGroup={onClickGroup}
                                  setGames={setGames} setUsers={setUsers} setCUser={setCUser}/>
                    </div>
                    : null}
                {betType === 'upcoming' ? <div>
                        <h2>Prochains matchs</h2>
                        <GameList games={upcomingGames} cUser={cUser} onClickGame={onclickGame} onClickGroup={onClickGroup}
                                  setGames={setGames} setUsers={setUsers} setCUser={setCUser}/>
                    </div>
                    : null}
                {betType === 'previous' ?
                    <div>
                        <h2>Anciens matchs</h2>
                        <GameList games={previousGames}
                                  cUser={cUser} onClickGroup={onClickGroup} onClickGame={onclickGame}
                                  setGames={setGames} setUsers={setUsers} inverted={true} setCUser={setCUser}/>
                    </div>
                    : null}
                {betType === 'special' ? <div>
                        <h2>Paris spéciaux</h2>
                        <SpecialBetList setUsers={setUsers} cUser={cUser} specialBets={specialBets}
                                        setSpecialBets={setSpecialBets} setUpdatedSpBet={setUpdatedSpBet}/>
                    </div>
                    : null}
            </div>
            <div className={styles.leaderboard}>
                <Leaderboard onClick={onClickUser} users={users} games={games} setUsers={setUsers} specialBets={specialBets}/>
            </div>
        </div>
    );
}

export default HomePage;
