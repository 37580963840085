import React, {useEffect, useState} from "react";
import type User from "../../dataType/user";
import {downloadSpecialBetList, uploadSingleUser, uploadSpecialBets} from "../../utilFiles/fireBaseStorage";
import styles from "../gameOverview/gameOverview.module.css";
import styles2 from "../gameList/gameList.module.css";


import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {Button} from "@material-tailwind/react";
import {userAddSpecialBet, userFindSpecialBet} from "../../dataType/user";

const specialURL = "https://eu-offering-api.kambicdn.com/offering/v2018/bnlbe/listView/football/euro_2024/all/all/competitions.json?lang=fr_BE&market=BE"

const betsName = [
    ["Vainqueur", "Euro 2024", 1],
    ["Meilleur buteur", "Meilleur buteur 2024", 2],
    ["Meilleur passeur", "Meilleures passes décisives 2024", 3]
]

export class SpecialBets {
    name: string;
    offers: [[string, number]];
    id: number;
    result: string;

    constructor(name, offers) {
        this.name = name;
        this.offers = offers;
        this.id = 0;
        this.result = "";
    }
}

export function createSpecialBetFromDic(jsonElem) {
    let specialBet = new SpecialBets(jsonElem["name"], jsonElem["offers"]);
    specialBet.id = jsonElem["id"];
    specialBet.result = jsonElem["result"];
    return specialBet;
}


export const createSpecialBets = async (setSpecialBets) => {
    let betList = [];

    console.log("createSpecialBets")

    fetch(specialURL)
        .then(response => response.json())
        .then(data => {
            let specialBetsData = data["events"];

            for (const betsNameElement of betsName) {
                let specialBet = new SpecialBets(betsNameElement[0], []);
                for (const specialBetsDataElement of specialBetsData) {
                    if (specialBetsDataElement['event']['name'] === betsNameElement[1]) {
                        for (const offer of specialBetsDataElement['betOffers'][0]['outcomes']) {
                            specialBet.offers.push([offer["label"], offer["odds"] / 1000]);
                        }
                    }
                }
                specialBet.id = betsNameElement[2];
                betList.push(specialBet);
            }
            setSpecialBets(betList);
        });
}


interface SpecialBetListProps {
    setUsers: (users: [User]) => void;
    cUser: User;
    specialBets: [SpecialBets];
    setSpecialBets: (specialBets: [SpecialBets]) => void;
    setUpdatedSpBet: (updatedSpBet: boolean) => void;
}


export const SpecialBetList: React.FC<SpecialBetListProps> = ({
                                                                  setUsers,
                                                                  cUser,
                                                                  specialBets,
                                                                  setSpecialBets,
                                                                  setUpdatedSpBet
                                                              }) => {
    const startDay = new Date("2024-06-14T19:00:00Z");
    const isPlaying = new Date() > startDay;


    if (!specialBets) {
        downloadSpecialBetList().then(data => {
            setSpecialBets(data);
        });
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isPlaying) {
                return;
            }
            createSpecialBets(setSpecialBets).then(() => {
                setUpdatedSpBet(true);
                console.log("Special bets updated");
            });
        }, 30000); // Run it every 30 seconds

        return () => clearInterval(intervalId); // Clean up on component unmount
    }, [setSpecialBets, isPlaying, setUpdatedSpBet]);

    return (
        <div className={styles2.gameList}>
            {specialBets ? (
                specialBets.map((specialBet) => (
                    specialBet ?
                        <SpecialBet key={specialBet.id} cUser={cUser} specialBet={specialBet}
                                    specialBetList={specialBets}
                                    isPlaying={isPlaying} setUsers={setUsers}/>
                        : null
                ))) : null}
        </div>
    )

}


interface SpecialBetProps {
    specialBet: SpecialBets;
    specialBetList: [SpecialBets];
    cUser: User;
    setUsers: (users: [User]) => void;
    isPlaying: boolean;
}

const SpecialBet: React.FC<SpecialBetProps> = ({specialBet, specialBetList, setUsers, cUser, isPlaying}) => {

    const [selectedRes, setSelectedRes] = useState(["", 0]);
    const [validated, setValidated] = useState(false);

    // alert("Is playing ?" + isPlaying);

    useEffect(() => {
        let oldBet = cUser ? userFindSpecialBet(cUser, specialBet.id) : null;
        if (oldBet) {
            setSelectedRes([oldBet[1], oldBet[2]]);
            setValidated(true);
        }
    }, [cUser, specialBet.id]);

    const funcSelectedRes = (label: string, odd: number) => {
        if (!isPlaying) {
            if (label !== selectedRes[0]) {
                setValidated(false)
                setSelectedRes([label, odd])
            }
        }
    }

    const validateSelection = () => {
        if (cUser) {
            userAddSpecialBet(cUser, specialBet.id, selectedRes[0], selectedRes[1]);
            uploadSingleUser(cUser, setUsers).then();
            setValidated(true);
        } else {
            alert("Aucun joueur sélectionné, le pari ne sera pas pris en compte\nConnecte toi en cliquant sur 'Pas de joueur choisi' et sélectionne ton joueur ou crée en un nouveau")
        }
        uploadSpecialBets(specialBetList).then();

    };


    const oddDisplay = (oddValue: number, label: string) => {
        if (oddValue === 0) {
            return label;
        }

        if (!selectedRes[0]) {
            return label + ' - ' + oddValue.toFixed(2);
        }
        if (selectedRes[0] === label && selectedRes[1] !== oddValue) {
            return label + ' - ' + "(" + selectedRes[1].toFixed(2) + ")\n" + oddValue.toFixed(2);
        } else {
            return label + ' - ' + oddValue.toFixed(2);
        }
    }

    function setButtonStyle(label: string) {
        // return `${styles.spCommonButton} ${styles.radioButton}`
        if (label === selectedRes[0]) {
            if (isPlaying) {
                if (!specialBet.result) {
                    return `${styles.spCommonButton} ${styles.running}`
                } else {
                    if (specialBet.result === label) {
                        return `${styles.spCommonButton} ${styles.success}`
                    } else {
                        return `${styles.spCommonButton} ${styles.lost}`
                    }
                }
            } else {
                if (validated) {
                    return `${styles.spCommonButton} ${styles.validated}`
                } else {
                    return `${styles.spCommonButton} ${styles.selected}`
                }
            }
        } else {
            return `${styles.spCommonButton} ${styles.radioButton}`
        }
    }


    let sortedOffers = specialBet.offers.sort((a, b) => a[1] - b[1]);

    return (
        <div className={styles.card}>
            <h2>{specialBet.name}</h2>
            <List className={styles.container_vertical}>
                {sortedOffers.map((offer, index) => (
                    <Button key={index} className={setButtonStyle(offer[0])}
                            onClick={() => funcSelectedRes(offer[0], offer[1])}>
                        <ListItemText primary={oddDisplay(offer[1], offer[0])}/>
                    </Button>
                ))}
                <button className={`${styles.commonButton} ${styles.validation}`}
                        onClick={validateSelection}>
                    Validate
                </button>
            </List>
        </div>
    )
}


