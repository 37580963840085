import {createTeamFromDict, getTeamByName, Team} from "./teamData";

export class Game {
    id: number
    stage: string
    teamA: Team
    teamB: Team
    result: string
    qResult: string
    startTime: string
    score: [number, number]
    odds: [number, number, number]
    qualifyOdd: [number, number]
    drawAt90: boolean

    constructor(stage, teamA, teamB, startTime) {
        this.id = 0;
        this.stage = stage;
        this.teamA = teamA;
        this.teamB = teamB;
        this.result = "";
        this.qResult = "";
        this.startTime = startTime;
        this.score = [];
        this.odds = [0, 0, 0];
        this.qualifyOdd = [0, 0];
        this.drawAt90 = false;
    }

    toString() {
        return this.teamA.name + " vs " + this.teamB.name;
    }
}

export function createGameFromDic(jsonElem) {
    let eventDic = jsonElem["event"];
    let teamA = getTeamByName(eventDic["homeName"]);
    let teamB = getTeamByName(eventDic["awayName"]);
    let startTime = eventDic["start"];

    return new Game("", teamA, teamB, startTime);
}


export function createGameFromDict(dict): Game {
    let teamA = createTeamFromDict(dict.teamA);
    let teamB = createTeamFromDict(dict.teamB);
    let game = new Game(dict.stage, teamA, teamB, dict.startTime);
    game.id = dict.id;
    game.result = dict.result;
    game.score = dict.score;
    game.odds = dict.odds;
    if (dict.qualifyOdd !== undefined) game.qualifyOdd = dict.qualifyOdd;
    if (dict.qResult !== undefined) game.qResult = dict.qResult;
    if (dict.drawAt90 !== undefined) game.drawAt90 = dict.drawAt90;
    return game;
}

export function getGameById(games: [Game], id: number): Game {
    return games.find(game => game.id === id);
}

export function getGameByTeams(games: [Game], teamA: Team, teamB: Team): Game {
    if (!games) return null;
    return games.find(game => game.teamA.name === teamA && game.teamB.name === teamB) || null;
}


export function saveGamesList(gameList: [Game]): void {
    localStorage.removeItem('games')
    localStorage.setItem('games', JSON.stringify(gameList));
}

export function getGamesList(): [Game] {
    let games = JSON.parse(localStorage.getItem('games'));
    alert("getGamesList" + games.length)
    return games
}


export function getGamesMaxId(games: [Game]): number {
    // let games = getGamesList();
    if (!games) return 0;
    let maxId = 0;
    for (let i = 0; i < games.length; i++) {
        if (games[i].id > maxId) {
            maxId = games[i].id;
        }
    }
    return maxId;
}



export function createUpdateGamesList(games, data) {
    if (!data) return games;
    if (!games) {
        games = [];
    }
    let maxId = getGamesMaxId(games);
    for (let i = 0; i < data.length; i++) {
        let cData = data[i];
        let eventData = cData['event'];
        const teamAName = eventData['homeName'];
        const teamBName = eventData['awayName'];
        let currentGame = getGameByTeams(games, teamAName, teamBName);

        if (!currentGame) {
            const teamA = getTeamByName(teamAName);
            const teamB = getTeamByName(teamBName);
            if (!teamA || !teamB) {
                return games;
            }
            currentGame = new Game("", teamA, teamB, eventData['start']);
            maxId += 1;
            currentGame.id = maxId;
            console.log("New game found " + currentGame.id + " " + currentGame.teamA.name + " " + currentGame.teamB.name);
            games.push(currentGame);

        }
        if (currentGame.result !== "") {
            continue;
        }
        let betData = cData['betOffers'];
        // alert(betData.length)
        let betSingleData = betData.find(bet => bet['criterion']['englishLabel'] === 'Full Time');
        // betData = betData[0]
        if (!betSingleData) {
            console.log("No bet data found for " + currentGame.teamA.name + " vs " + currentGame.teamB.name);
            continue;
        }
        for (const betOdd of betSingleData['outcomes']) {
            if (betOdd['label'] === '1') {
                currentGame.odds[0] = betOdd['odds'] / 1000;
            } else if (betOdd['label'] === 'X') {
                currentGame.odds[1] = betOdd['odds'] / 1000;
            } else if (betOdd['label'] === '2') {
                currentGame.odds[2] = betOdd['odds'] / 1000;
            }
        }
    }
    return games;
}

export default Game;
