import React, {useState} from 'react';
import {createNewUser, User} from "../../../dataType/user";
import {addUser, uploadSingleUser} from "../../../utilFiles/fireBaseStorage";


interface CreateUserBarProps {
    cUser: User;
    setCreateUserMode: (mode: boolean) => void;
    setCUser: (user: User) => void;
    setUsers: (users: [User]) => void;
    users: [User];
    newUserMode: boolean;
}

const CreateUserBar: React.FC<CreateUserBarProps> = ({
                                                         cUser,
                                                         setCreateUserMode,
                                                         setCUser,
                                                         setUsers,
                                                         users,
                                                         newUserMode
                                                     }) => {
    const [username, setUsername] = useState('');
    const [picture, setPicture] = useState('');

    const handleSubmit = (event) => {
        if (newUserMode) {
            if (username === "") {
                return;
            }
            const newUser = createNewUser(users, username, picture);
            setCreateUserMode(false);
            addUser(newUser, setUsers).then();
        } else {
            if (username !== "") {
                cUser.username = username;
            }
            if (picture !== "") {
                cUser.picture = picture;
            }
            setCUser(null);
            console.log("User modified: ", username, " ", picture);
            console.log("User modified: ", cUser.username, " ", cUser.picture);
            uploadSingleUser(cUser, setUsers).then();

        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Nom:
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}/>
            </label>
            <label>
                URL de la photo de profil:
                <input type="text" value={picture} onChange={(e) => setPicture(e.target.value)}/>
            </label>
            <input type="submit" value={newUserMode ? "Créer un profil" : "Modifier un profil"}/>
        </form>
    );
}

export default CreateUserBar;
