import Game from "./game";

export class Team {
    name: string
    countryCode: string
    id: number
    group: string

    constructor(name, countryCode, id) {
        this.name = name;
        this.countryCode = countryCode;
        this.id = id;
    }

    static fromJson(json) {
        return new Team(json.name, json.countryCode)
    }

    toString() {
        return {"id": this.id, "name": this.name, "countryCode": this.countryCode}
    }
}


export function createTeamsList() {
    let teamNames = ['Allemagne', 'Écosse', 'Hongrie', 'Suisse',
        'Espagne', 'Croatie', 'Italie', 'Albanie', 'Slovénie', 'Danemark', 'Serbie',
        'Angleterre', 'Pologne', 'Pays-Bas', 'Autriche', 'France', 'Belgique', 'Slovaquie',
        'Roumanie', 'Ukraine', 'Turquie', 'Géorgie', 'Portugal', 'République Tchèque']
    let teamCodes = ['de', 'gb-sct', 'hu', 'ch', 'es', 'hr', 'it', 'al', 'si', 'dk', 'rs', 'gb-eng', 'pl',
        'nl', 'at', 'fr', 'be', 'sk', 'ro', 'ua', 'tr', 'ge', 'pt', 'cz']
    const groups = ["A", "B", "C", "D", "E", "F"]
    let teams = []
    for (let i = 0; i < teamNames.length; i++) {
        let team = new Team(teamNames[i], teamCodes[i], i)
        team.group = groups[Math.floor(i / 4)]
        teams.push(team)
    }

    localStorage.setItem(
        "teams",
        JSON.stringify(teams)
    );
}

export function getTeamsList() {
    return JSON.parse(localStorage.getItem("teams"))
}

export function getTeamById(id) {
    let teams = getTeamsList()
    for (let i = 0; i < teams.length; i++) {
        if (teams[i].id === id) {
            return teams[i]
        }
    }
    return null
}


export function getTeamByName(name) {
    let teams = getTeamsList()
    for (let i = 0; i < teams.length; i++) {
        if (teams[i].name === name) {
            return teams[i]
        }
    }
    return null
}

export function createTeamFromDict(dict): Team {
    let team = new Team(dict.name, dict.countryCode, dict.id);
    team.group = dict.group;
    return team;
}


export default Team;
