import React from 'react';
import {getUsersList, updateUsersPoints, User} from '../../dataType/user';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import styles from './leaderBoard.module.css';
import {Avatar} from "@mui/material";
import type Game from "../../dataType/game";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface LeaderBoardProps {
    onClick: (user: User) => void;
    users: [User];
    games: [Game];
    specialBets: [SpecialBets];
}

const baseIMG = "https://scontent.xx.fbcdn.net/v/t1.15752-9/48413865_1005940086262445_5551458711429447680_n.png?stp=dst-png_p100x100&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=d74pei6lZzIQ7kNvgFuvZ5O&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_Q7cD1QHgvhuACOVcC8GJd2xfH1k7HQ98MzO4Xu7Ua95c8iLGLA&oe=66859653";


const LeaderBoard: React.FC<LeaderBoardProps> = ({onClick, users, games, specialBets}) => {
    // Sort users in descending order based on points
    // let users = getUsersList()
    const [open, setOpen] = React.useState(true);
    users = updateUsersPoints(users, games, specialBets);
    let sortedUsers = users ? [...users].sort((a, b) => b.points - a.points) : [];
    const cols = ["#", "User", "Points"];

    function headCell(col: string) {
        if (col === "Points") {
            return <TableCell key={"head-" + col} align="center">
                <div>{col}</div>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
        }
        return <TableCell key={"head-" + col} align="center">{col}</TableCell>;
    }


    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow key={"head"} className={styles.row}>
                            <TableCell key={"head-#"} align="left">#</TableCell>
                            <TableCell key={"head-Joueur"} align="center">Joueur</TableCell>
                            <TableCell key={"head-Points"} align="right" style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
                                Points
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {open && <TableBody>
                        {sortedUsers.map((user, index) => (
                            <TableRow className={styles.row}
                                      key={user.username}
                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{index + 1}</TableCell>
                                <TableCell align={"center"}>
                                    <button className={styles.button} onClick={() => onClick(user)}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                                            <Avatar variant="circular" alt="candice"
                                                    src={user.picture ? user.picture : baseIMG}/>
                                            {user.username}
                                        </div>
                                    </button>
                                </TableCell>
                                <TableCell align="right">{user.points}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    }
                </Table>
            </TableContainer>
        </div>

    );
};

export default LeaderBoard;
