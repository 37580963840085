import './App.css';
import {createTeamsList} from "./dataType/teamData";
import Game, {createUpdateGamesList} from "./dataType/game";
import User from "./dataType/user";
import TopBar from "./components/topBar/topBar";
import HomePage from "./components/pages/homePage/homePage";
import React, {useEffect, useState} from "react";
import {makeRequest} from "./utilFiles/getInfo";
import UserPage from "./components/pages/userPage/userPage";
import GroupPage from "./components/pages/groupPage/groupPage";
import CreateUserBar from "./components/pages/createUserBar/createUserBar";
import {
    downloadGamesList, downloadSpecialBetList, downloadUserList, uploadGames, uploadSingleUser
} from "./utilFiles/fireBaseStorage";
import {SpecialBetList} from "./components/specialBets/specialBets";
import GamePage from "./components/pages/gamePage/gamePage";


function App() {
    const [page, setPage] = useState("home");
    const [userId, setUserId] = useState(1);
    const [groupId, setGroupId] = useState('A');
    const [currentUser: User, setCurrentUser] = useState(null);
    const [currentGame, setCurrentGame] = useState(null);
    const [updateKey, setUpdateKey] = useState(0);
    const [createUserMode, setCreateUserMode] = useState(false);
    const [games: [Game], setGames] = useState([]);
    const [users: [User], setUsers] = useState([]);
    const [specialBets, setSpecialBets] = useState(null);

    const onPageChange = (pageName: string) => {
        setPage(pageName);
        setCreateUserMode(false);
    }

    const changeToHome = () => {
        setPage("home");
        uploadGames(games).then();
    }

    const onClickSelectUser: User = (user) => {
        setCurrentUser(user);
        onPageChange("home");
    }

    const onClickUser: User = (user) => {
        setUserId(user.id)
        onPageChange("user")
    }

    const onClickGame: Game = (game) => {
        setCurrentGame(game)
        setPage("game")
    }

    const onClickGroup: Game = (game) => {
        setGroupId(game.teamA.group)
        onPageChange("group")
    }

    if (updateKey === 0) {
        setUpdateKey(1);
        createTeamsList();
        downloadGamesList().then((data) => {
            setGames(data);
            downloadUserList().then((data) => {
                setUsers(data);
            });
        });
    }

    if (!specialBets) {
        downloadSpecialBetList().then(data => {
            setSpecialBets(data);
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            let data = await makeRequest();
            let gamesList = createUpdateGamesList(games, data);
            setGames(gamesList);
        };

        const intervalId = setInterval(() => {
            console.log("Fetching data... number of games: " + games.length);
            fetchData().then(() => {
                console.log("Data fetched successfully: number of games: " + games.length);
            });
        }, 120000); // Run it every 120 seconds

        return () => clearInterval(intervalId); // Clean up on component unmount
    }, [games]);


    const [info, setInfo] = useState(null);
    useEffect(() => {
        makeRequest().then(data => {
            setInfo(data[0])
        });
    }, []);

    /*return (
        <div className="App">
            <TopBar toHomePage={changeToHome} setCurrentUser={onClickSelectUser} cUser={currentUser}
                    users={users} setCreateUserMode={setCreateUserMode}></TopBar>
            <SpecialBetList setUsers={setUsers()} cUser={currentUser} />
        </div>
    )*/

    return (
        <div className="App">

            <TopBar toHomePage={changeToHome} setCurrentUser={onClickSelectUser} cUser={currentUser}
                    users={users} setCreateUserMode={setCreateUserMode}></TopBar>
            {createUserMode ?
                <CreateUserBar cUser={currentUser} users={users} setCreateUserMode={setCreateUserMode}
                               setUsers={setUsers} setCUser={setCurrentUser} newUserMode={true}/> : null}
            {page === "home" ? <HomePage games={games} users={users} cUser={currentUser} onClickUser={onClickUser}
                                         onClickGroup={onClickGroup} onclickGame={onClickGame} setGames={setGames}
                                         setUsers={setUsers}
                                         specialBets={specialBets} setSpecialBets={setSpecialBets}
                                         setCUser={setCurrentUser}/> : null}
            {page === "user" ?
                <UserPage cUser={currentUser} users={users} userId={userId} games={games} onClickGroup={onClickGroup}
                          onClickGame={onClickGame}
                          setCurrentUser={setCurrentUser} setUsers={setUsers} setCUser={setCurrentUser}/> : null}
            {page === "group" ?
                <GroupPage letter={groupId} cUser={currentUser} games={games}
                           onClickGroup={onClickGroup} setGames={setGames} setUsers={setUsers}
                           setCUser={setCurrentUser}/> : null}
            {page === "game" ?
                <GamePage game={currentGame} users={users} cUser={currentUser} onClickUser={onClickUser}
                          setGames={setGames}/>
                : null}

        </div>
    );
}

export default App;
