import React from "react";
import styles from "./groupPage.module.css";
import GameList from "../../gameList/GameList"; // Import your GameList component
import type Game from "../../../dataType/game";
import {getTeamsList} from "../../../dataType/teamData";
import TeamTable from "../../teamTable/teamTable";
import type User from "../../../dataType/user"; // Import your Leaderboard component


interface GroupPageProps {
    letter: string;
    games: [Game];
    cUser: User;
    onclickGame: (game: Game) => void;
    setGames: (games: [Game]) => void;
    setUsers: (users: User[]) => void;
    setCUser: (user: User) => void;
}


const GroupPage: GroupPageProps = ({letter, cUser, games, onClickGame, setGames, setUsers, setCUser}) => {
    let gamesGroup = [];
    if (games) {
        gamesGroup = games.filter(game => game.teamA.group === letter || game.teamB.group === letter);
    }
    let teams = getTeamsList()
    let teamsGroup = teams.filter(team => team.group === letter);
    let groupGames = games.filter(game => game.teamA.group === letter || game.teamB.group === letter);


    return (
        <div>
            <div>
                <h1>Group {letter}</h1>
                <TeamTable teams={teamsGroup} groupGames={groupGames}/>
            </div>
            <div>
                <GameList games={gamesGroup} cUser={cUser} onClickGame={onClickGame}
                          onClickGroup={() => {}} setGames={setGames} setUsers={setUsers} setCUser={setCUser}/>
            </div>
        </div>
    );
}

export default GroupPage;
