import React from 'react';
import style from './topbar.module.css'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {getUsersList, saveUsersList, User} from "../../dataType/user";
import blason from './blason.png';
import logo from './logo-home.png';
import {uploadGames} from "../../utilFiles/fireBaseStorage";

interface TopBarProps {
    toHomePage: () => void;
    setCurrentUser: (user: User) => void;
    cUser: User;
    users: User[];
    setCreateUserMode: (mode: boolean) => void;
}

const baseIMG = "https://scontent.xx.fbcdn.net/v/t1.15752-9/48413865_1005940086262445_5551458711429447680_n.png?stp=dst-png_p100x100&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=d74pei6lZzIQ7kNvgFuvZ5O&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_Q7cD1QHgvhuACOVcC8GJd2xfH1k7HQ98MzO4Xu7Ua95c8iLGLA&oe=66859653";


const TopBar: React.FC<TopBarProps> = ({toHomePage, setCurrentUser, cUser, users, setCreateUserMode}) => {
    return (
        <div className={style.top_bar}>
            <div className={style.left}>
                <button className={style.button} onClick={toHomePage}>
                    <img className={style.blason} src={logo} alt={"Home"}/>
                </button>
            </div>
            <div className={style.center}>
                <img className={style.logo} src={blason} alt={"Euro Rageux 2024"}></img>
            </div>
            <div className={style.right}>
                <NavListMenu cUser={cUser} setCurrentUser={setCurrentUser} userList={users}
                             setCreateUserMode={setCreateUserMode}></NavListMenu>
            </div>

        </div>
    );
};

function NavListMenu({cUser, setCurrentUser, setCreateUserMode, userList}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // let userList = getUsersList();

    let renderUsers = userList ? userList.map(
        (user) => (
            <MenuItem style={{gap: '10px'}} key={user.id} onClick={() => {
                setCurrentUser(user);
                handleClose();
            }}>
                <Avatar alt={user.username}
                        src={user.picture ? user.picture : baseIMG}/>
                <Typography variant="paragraph" className="text-xs !font-medium text-blue-gray-500">
                    {user.username}
                </Typography>
            </MenuItem>
        ),
    ) : [];

    return (
        <div>
            <button className={style.button} onClick={handleClick}>
                {cUser ? (
                        <div className={style.userDisplay}>
                            <Avatar variant="circular" alt={cUser.username}
                                    src={cUser.picture ? cUser.picture : baseIMG}/>
                            <h3>
                                {cUser.username}
                            </h3>
                        </div>)
                    : <h3>
                        Pas de joueur choisi
                    </h3>}
            </button>
            <Menu
                anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {renderUsers}
                <MenuItem key={"UserCreation"} onClick={() => {
                    setCreateUserMode(true);
                    handleClose();
                }}>Nouveau joueur</MenuItem>
            </Menu>
        </div>
    );
}

export default TopBar;
