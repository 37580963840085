import React, {useEffect, useState} from "react";
import {Game} from "../../dataType/game";
import styles from "./gameOverview.module.css";
import classes from "../teamView/teamView.module.css";
import type User from "../../dataType/user";
import {userAddQGame, userFindMatchBet, userFindQualifyBet} from "../../dataType/user";
import {uploadSingleUserGame} from "../../utilFiles/fireBaseStorage";
import ScoreManager from "../scoreManager/scoreManager";
import ScoreManagerQuali from "../scoreManager/scoreManagerQuali";


interface GameOverviewProps {
    game: Game;
    cUser: User;
    onClickGroup: (game: Game) => void;
    setGames: (games: [Game]) => void;
    setUsers: (users: [User]) => void;
    setCUser: (user: User) => void;
    onClickGame: (game: Game) => void;
}


const GameOverview: GameOverviewProps = ({game, cUser, onClickGroup, onClickGame, setGames, setUsers, setCUser}) => {
    const gameStart = new Date(game.startTime);
    const gameEnd = new Date(gameStart.getTime());
    gameEnd.setMinutes(gameStart.getMinutes() + 105);
    const today = new Date();
    let isPlaying = today > gameStart;
    const isFinished = today > gameEnd;
    const [selectedRes, setSelectedRes] = useState(["", 0]);
    const [selectedQualify, setSelectedQualify] = useState(["", 0]);
    const [validated, setValidated] = useState(false);


    useEffect(() => {
        let oldBet = cUser ? userFindMatchBet(cUser, game.id) : null;
        let oldQualifyBet = cUser ? userFindQualifyBet(cUser, game.id) : null;
        if (oldBet) {
            setSelectedRes([oldBet[1], oldBet[2]]);
            if (oldQualifyBet) {
                setSelectedQualify([oldQualifyBet[1], oldQualifyBet[2]])
            }
            setValidated(true);
        }
    }, [cUser, game.id]);

    const funcSelectedRes = (label: string, odd: number) => {
        if (!isPlaying) {
            if (label !== selectedRes[0]) {
                setValidated(false)
                setSelectedRes([label, odd])
            }
        }
    }

    const funcSelectedQRes = (qLabel: string, qOdd: number) => {
        if (!isPlaying) {
            if (qLabel !== selectedQualify[0]) {
                setValidated(false)
                setSelectedQualify([qLabel, qOdd])
            }
        }
    }

    const validateSelection = () => {
        if (cUser) {
            if (new Date() > gameStart) {
                alert("Le match a déjà commencé, tu ne peux plus parier dessus");
                return;
            }
            userAddQGame(cUser, game.id, selectedRes[0], selectedRes[1], selectedQualify[0], selectedQualify[1]);
            uploadSingleUserGame(cUser, game.id, setUsers, setCUser).then();
            setValidated(true);
        } else {
            alert("Aucun joueur sélectionné, le pari ne sera pas pris en compte\nConnecte toi en cliquant sur 'Pas de joueur choisi' et sélectionne ton joueur ou crée en un nouveau")
        }

    };


    function setButtonStyle(label: string) {
        if (label === selectedRes[0]) {
            if (isPlaying) {
                if (!game.result) {
                    return `${styles.commonButton} ${styles.running}`
                } else {
                    if (game.result === label) {
                        return `${styles.commonButton} ${styles.success}`
                    } else {
                        return `${styles.commonButton} ${styles.lost}`
                    }
                }
            } else {
                if (validated) {
                    return `${styles.commonButton} ${styles.validated}`
                } else {
                    return `${styles.commonButton} ${styles.selected}`
                }
            }
        } else {
            return `${styles.commonButton} ${styles.radioButton}`
        }
    }

    function setQButtonStyle(qLabel: string) {
        if (qLabel === selectedQualify[0]) {
            if (isPlaying) {
                if (!game.qResult) {
                    return `${styles.commonButton} ${styles.running}`
                } else {
                    if (game.qResult === qLabel) {
                        return `${styles.commonButton} ${styles.success}`
                    } else {
                        return `${styles.commonButton} ${styles.lost}`
                    }
                }
            } else {
                if (validated) {
                    return `${styles.commonButton} ${styles.validated}`
                } else {
                    return `${styles.commonButton} ${styles.selected}`
                }
            }
        } else {
            return `${styles.commonButton} ${styles.radioButton}`
        }
    }

    const oddDisplay = (selectedVal: [string, number], oddValue: number, label: string) => {
        if (oddValue === 0) {
            return label;
        }
        if (!selectedVal[0] || game.result) {
            return oddValue.toFixed(2);
        }
        if (selectedVal[0] === label && selectedVal[1] !== oddValue) {
            return "(" + selectedVal[1].toFixed(2) + ")\n" + oddValue.toFixed(2);
        } else {
            return oddValue.toFixed(2);
        }
    }

    const CustomRadioButton = ({label, style, onSelect, oddValue}) => (
        <li>
            <button className={style} onClick={onSelect}>
                {oddDisplay(selectedRes, oddValue, label)}
            </button>
        </li>
    );

    const CustomQRadioButton = ({label, style, onSelect, oddValue}) => (
        <li>
            <button className={style} onClick={onSelect}>
                {oddDisplay(selectedQualify, oddValue, label)}
            </button>
        </li>
    );


    const center = () => {
        if (isPlaying) {
            if (!game.result) {
                return <div>
                    <button className={styles.button} onClick={() => onClickGame(game)}>
                        <h2>En cours</h2>
                    </button>
                </div>
            } else {
                return <div>
                    <button className={styles.button} onClick={() => onClickGame(game)}>
                        <h2>{game.score[0] + "-" + game.score[1]}</h2>
                        {
                            game.drawAt90 ?
                                game.score[0] === game.score[1] ?
                                    game.qResult === "1" ?
                                        <p style={{margin: '0.5em 0'}}>Victoire de {game.teamA.name} aux pénaltys</p> :
                                        <p style={{margin: '0.5em 0'}}>Victoire de {game.teamB.name} aux pénaltys</p> :
                                    <p style={{margin: '0.5em 0'}}>Match nul à 90'</p> : null}
                    </button>
                </div>
            }
        } else {
            return <div>
                <p style={{margin: '0.5em 0'}}>{gameStart.toLocaleDateString([], {
                    day: '2-digit',
                    month: '2-digit'
                })}</p>
                <p style={{margin: '0.5em 0'}}>{gameStart.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                })}</p>
            </div>
        }
    }


    return <div className={styles.card}>
        <div>
            <button className={styles.button} onClick={() => onClickGroup(game)}>
                <h2>{game.stage ? game.stage : (game.teamA.group === game.teamB.group ? 'Groupe ' + game.teamA.group : '')}</h2>
            </button>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className={classes.team}>
                    <span>{game.teamA.name}</span>
                    <img className={classes.flag} src={`https://flagcdn.com/${game.teamA.countryCode}.svg`} width="50"
                         alt={`${game.teamA.name}`}/>
                </div>
                {center()}
                <div className={classes.team}>
                    <span>{game.teamB.name}</span>
                    <img className={classes.flag} src={`https://flagcdn.com/${game.teamB.countryCode}.svg`} width="50"
                         alt={`${game.teamB.name}`}/>
                </div>
            </div>
            <div className={styles.container}>

                <ul className={styles.verticalList}>
                    <ul className={styles.list}>
                        <CustomRadioButton label="1" style={setButtonStyle("1")} oddValue={game.odds[0]}
                                           onSelect={() => funcSelectedRes("1", game.odds[0])}/>
                        <CustomRadioButton label="X" style={setButtonStyle("X")} oddValue={game.odds[1]}
                                           onSelect={() => funcSelectedRes("X", game.odds[1])}/>
                        <CustomRadioButton label="2" style={setButtonStyle("2")} oddValue={game.odds[2]}
                                           onSelect={() => funcSelectedRes("2", game.odds[2])}/>
                    </ul>
                    <ul className={styles.list}>
                        <CustomQRadioButton label="1" style={setQButtonStyle("1")} oddValue={game.qualifyOdd[0]}
                                            onSelect={() => funcSelectedQRes("1", game.qualifyOdd[0])}/>
                        <CustomQRadioButton label="2" style={setQButtonStyle("2")} oddValue={game.qualifyOdd[1]}
                                            onSelect={() => funcSelectedQRes("2", game.qualifyOdd[1])}/>
                        <button className={`${styles.commonButton} ${styles.validation}`}
                                onClick={validateSelection}>
                            Validate
                        </button>
                    </ul>

                </ul>

            </div>
            {isFinished && !game.result && (
                <ScoreManagerQuali game={game} setGames={setGames}/>
            )}
        </div>
    </div>
}


export default GameOverview;
