import styles from "../gameOverview/gameOverview.module.css";
import React, {useState} from "react";
import {uploadSingleGame} from "../../utilFiles/fireBaseStorage";
import type Game from "../../dataType/game";


interface ScoreManagerProps {
    game: Game;
    setGames: (games: [Game]) => void;
}


const ScoreManager: React.FC<ScoreManagerProps> = ({game, setGames}) => {
    const [score: [number, number], setScore] = useState([0, 0]);


    const handleScoreSubmit = (event) => {
        event.preventDefault();
        // Here you can handle the score submission logic
        console.log(`Score for Team A: ${score[0]}, Score for Team B: ${score[1]}`);
        if (game.score) {
            game.score = [Number(score[0]), Number(score[1])];
        } else {
            game.score[0] = Number(score[0]);
            game.score[1] = Number(score[1]);
        }
        if (score[0] > score[1]) {
            game.result = "1";
        } else if (score[0] < score[1]) {
            game.result = "2";
        } else {
            game.result = "X";
        }
        uploadSingleGame(game, setGames).then();
    };

    return (
        <form onSubmit={handleScoreSubmit} className={styles.verticalFlex}>
            <label>
                A:
                <input type="number" className={styles.inputShort} value={score[0]}
                       onChange={(e) => setScore([e.target.value, score[1]])}/>
            </label>
            <label>
                B:
                <input type="number" className={styles.inputShort} value={score[1]}
                       onChange={(e) => setScore([score[0], e.target.value])}/>
            </label>
            <input type="submit" value="Validate"/>
        </form>);
}

export default ScoreManager;
