import React, {useEffect, useState} from "react";
import {Game} from "../../dataType/game";
import TeamView from "../teamView/teamView";
import styles from "./gameOverview.module.css";
import classes from "../teamView/teamView.module.css";
import type User from "../../dataType/user";
import {saveUserChanges, userAddGame, userFindMatchBet, userFindQualifyBet} from "../../dataType/user";
import {uploadSingleGame, uploadSingleUser, uploadSingleUserGame} from "../../utilFiles/fireBaseStorage";
import ScoreManager from "../scoreManager/scoreManager";


interface GameOverviewProps {
    game: Game;
    cUser: User;
    onClickGroup: (game: Game) => void;
    setGames: (games: [Game]) => void;
    setUsers: (users: [User]) => void;
    setCUser: (user: User) => void;
    onClickGame: (game: Game) => void;
}


const GameOverview: GameOverviewProps = ({game, cUser, onClickGroup, onClickGame, setGames, setUsers, setCUser}) => {
    const gameStart = new Date(game.startTime);
    const gameEnd = new Date(gameStart.getTime());
    gameEnd.setMinutes(gameStart.getMinutes() + 105);
    const today = new Date();
    let isPlaying = today > gameStart;
    const isFinished = today > gameEnd;
    // isPlaying = true;
    // game.score = [0, 0];
    // game.result = "";
    const [selectedRes, setSelectedRes] = useState(["", 0]);
    const [validated, setValidated] = useState(false);


    useEffect(() => {
        let oldBet = cUser ? userFindMatchBet(cUser, game.id) : null;
        if (oldBet) {
            setSelectedRes([oldBet[1], oldBet[2]]);
            setValidated(true);
        }
    }, [cUser, game.id]);

    const funcSelectedRes = (label: string, odd: number) => {
        if (!isPlaying) {
            if (label !== selectedRes[0]) {
                setValidated(false)
                setSelectedRes([label, odd])
            }
        }
    }

    const validateSelection = () => {
        if (cUser) {
            if (new Date() > gameStart) {
                alert("Le match a déjà commencé, tu ne peux plus parier dessus");
                return;
            }
            userAddGame(cUser, game.id, selectedRes[0], selectedRes[1]);
            uploadSingleUserGame(cUser, game.id, setUsers, setCUser).then();
            setValidated(true);
        } else {
            alert("Aucun joueur sélectionné, le pari ne sera pas pris en compte\nConnecte toi en cliquant sur 'Pas de joueur choisi' et sélectionne ton joueur ou crée en un nouveau")
        }

    };

    function setButtonStyle(label: string) {
        if (label === selectedRes[0]) {
            if (isPlaying) {
                if (!game.result) {
                    return `${styles.commonButton} ${styles.running}`
                } else {
                    if (game.result === label) {
                        return `${styles.commonButton} ${styles.success}`
                    } else {
                        return `${styles.commonButton} ${styles.lost}`
                    }
                }
            } else {
                if (validated) {
                    return `${styles.commonButton} ${styles.validated}`
                } else {
                    return `${styles.commonButton} ${styles.selected}`
                }
            }
        } else {
            return `${styles.commonButton} ${styles.radioButton}`
        }
    }

    const oddDisplay = (oddValue: number, label: string) => {
        if (oddValue === 0) {
            return label;
        }

        if (!selectedRes[0] || game.result) {
            return oddValue.toFixed(2);
        }
        if (selectedRes[0] === label && selectedRes[1] !== oddValue) {
            return "(" + selectedRes[1].toFixed(2) + ")\n" + oddValue.toFixed(2);
        } else {
            return oddValue.toFixed(2);
        }
    }

    const CustomRadioButton = ({label, style, onSelect, oddValue}) => (
        <li>
            <button className={style} onClick={onSelect}>
                {oddDisplay(oddValue, label)}
            </button>
        </li>
    );


    const center = () => {
        if (isPlaying) {
            if (!game.result) {
                return <div>
                    <button className={styles.button} onClick={() => onClickGame(game)}>
                        <h2>En cours</h2>
                    </button>
                </div>
            } else {
                return <div>
                    <button className={styles.button} onClick={() => onClickGame(game)}>
                        <h2>{game.score[0] + "-" + game.score[1]}</h2>
                    </button>
                </div>
            }
        } else {
            return <div>
                <p style={{margin: '0.5em 0'}}>{gameStart.toLocaleDateString([], {
                    day: '2-digit',
                    month: '2-digit'
                })}</p>
                <p style={{margin: '0.5em 0'}}>{gameStart.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                })}</p>
            </div>
        }
    }


    return <div className={styles.card}>
        <div>
            <button className={styles.button} onClick={() => onClickGroup(game)}>
                <h2>{game.stage ? game.stage : (game.teamA.group === game.teamB.group ? 'Groupe ' + game.teamA.group : '')}</h2>
            </button>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className={classes.team}>
                    <span>{game.teamA.name}</span>
                    <img className={classes.flag} src={`https://flagcdn.com/${game.teamA.countryCode}.svg`} width="50"
                         alt={`${game.teamA.name}`}/>
                </div>
                {center()}
                <div className={classes.team}>
                    <span>{game.teamB.name}</span>
                    <img className={classes.flag} src={`https://flagcdn.com/${game.teamB.countryCode}.svg`} width="50"
                         alt={`${game.teamB.name}`}/>
                </div>
            </div>
            <div className={styles.container}>
                <ul className={styles.list}>
                    <CustomRadioButton label="1" style={setButtonStyle("1")} oddValue={game.odds[0]}
                                       onSelect={() => funcSelectedRes("1", game.odds[0])}/>
                    <CustomRadioButton label="X" style={setButtonStyle("X")} oddValue={game.odds[1]}
                                       onSelect={() => funcSelectedRes("X", game.odds[1])}/>
                    <CustomRadioButton label="2" style={setButtonStyle("2")} oddValue={game.odds[2]}
                                       onSelect={() => funcSelectedRes("2", game.odds[2])}/>
                    <button className={`${styles.commonButton} ${styles.validation}`}
                            onClick={validateSelection}>
                        Validate
                    </button>
                </ul>
            </div>
            {

            }
            {isFinished && !game.result && (
                <ScoreManager game={game} setGames={setGames}/>
            )}
        </div>
    </div>
}


export default GameOverview;
