import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import type Team from "../../dataType/teamData";
import classes from "../teamView/teamView.module.css";
import type Game from "../../dataType/game";

interface TeamTableProps {
    teams: [Team];
    groupGames: [Game];
}


export const TeamTable: React.FC<TeamTableProps> = ({teams, groupGames}) => {
    const cols = ["#", "", "Nom", "J", "G", "N", "D", "+/-", "DB", "Pts"]

    let wins = [];
    let draws = [];
    let losses = [];
    let points = [];
    let goals = [];
    let goalsAgainst = [];


    let playedGames = groupGames.filter((game) => game.result !== "");

    teams.forEach((team) => {
        wins.push(playedGames.filter((game) => (game.result === "1" && game.teamA.name === team.name) || (game.result === "2" && game.teamB.name === team.name)).length);
        draws.push(playedGames.filter((game) => game.result === "X" && (game.teamA.name === team.name || game.teamB.name === team.name)).length);
        losses.push(playedGames.filter((game) => (game.result === "2" && game.teamA.name === team.name) || (game.result === "1" && game.teamB.name === team.name)).length);
        points.push(wins[wins.length - 1] * 3 + draws[draws.length - 1]);
        goals.push(playedGames.filter((game) => (game.teamA.name === team.name || game.teamB.name === team.name)).reduce((acc: number, game) => acc + Number(game.teamA.name === team.name ? game.score[0] : game.score[1]), 0));
        goalsAgainst.push(playedGames.filter((game) => (game.teamA.name === team.name || game.teamB.name === team.name)).reduce((acc: number, game) => acc + Number(game.teamA.name === team.name ? game.score[1] : game.score[0]), 0));
    });
    let goalDifference = goals.map((goal, index) => goal - goalsAgainst[index]);

    let idxs = Array.from(Array(teams.length).keys());
    idxs.sort((a, b) => {
        if (points[a] !== points[b]) {
            return points[b] - points[a];
        }
        if (goalDifference[a] !== goalDifference[b]) {
            return goalDifference[b] - goalDifference[a];
        }
        return goals[b] - goals[a];
    });

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow key={"header"}>
                        {cols.map((col) => (<TableCell key={"header-" + col} align="right">{col}</TableCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {idxs.map((idx, index) => (
                        <TableRow
                            key={teams[idx].name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell key={teams[idx].name + "-pos"} align="right">{index + 1} </TableCell>
                            <TableCell key={teams[idx].name + "-flag"} align="right">
                                <img className={classes.flag} src={`https://flagcdn.com/${teams[idx].countryCode}.svg`}
                                     width="50" alt={`${teams[idx].name}`}/>
                            </TableCell>
                            <TableCell key={teams[idx].name + "-name"} align="right">{teams[idx].name}</TableCell>
                            <TableCell key={teams[idx].name + "-J"} align="right">{wins[idx] + draws[idx] + losses[idx]}</TableCell>
                            <TableCell key={teams[idx].name + "-G"} align="right">{wins[idx]}</TableCell>
                            <TableCell key={teams[idx].name + "-N"} align="right">{draws[idx]}</TableCell>
                            <TableCell key={teams[idx].name + "-D"} align="right">{losses[idx]}</TableCell>
                            <TableCell key={teams[idx].name + "-Goals"} align="right">{(goals[idx]) + "-" + (goalsAgainst[idx])}</TableCell>
                            <TableCell key={teams[idx].name + "-DB"} align="right">{goalDifference[idx]}</TableCell>
                            <TableCell key={teams[idx].name + "-points"} align="right">{points[idx]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default TeamTable;
