import {getDownloadURL, getStorage, listAll, ref, uploadBytes} from "firebase/storage";
import {initializeApp} from "firebase/app"
import type User from "../dataType/user";
import type Game from "../dataType/game";
import {createUserFromDict} from "../dataType/user";
import {createGameFromDict} from "../dataType/game";
import type {SpecialBets} from "../components/specialBets/specialBets";
import {createSpecialBetFromDic} from "../components/specialBets/specialBets";


let firebaseConfig = {
    "type": "service_account",
    "project_id": "eurorageux",
    "private_key_id": "37a32f82c22ecbe337a136185555413bc51bd369",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCkqc8iIujB8X3w\n/g8VAfxUiEb+8EAWsyYXmhW/e3BC68Li4XTyICF/qhuX+H/FHZtv5dFL+A3CDsP9\n5OYvKMyq0DRyqL42RaSRcPyX02GcLkUBMOPIG4bXN4LAM0rfy56XtGQmx8hgkGXq\nk/gvbW207HFHf7K0XkEqvfnC/n1f2xmkwWFUlqQga89zR6ti+mO1BPEzVWKA6A2A\nCsCmWZi1nYUHtXRYWoGt5ylcp/dwIG4nS4WFwXLBjPvnRRH33T4sMRMGg+oxriiw\nJUln9BWiCmF9TknY36eDRfg278r5+V82Z6h/0jYM9UpYzFO3Kd03k/KQkxcL22YT\n/+DHnnKNAgMBAAECggEAB5Kfqgw89B5tElY8z1SGDEUwLq1R5Tlb+R3m/iVpJkq9\nbb28qR39LGfgWP7esWwJJ/5Dv+CqkVAoh1atl2Gi+AAXSWalSM2vIdk2ZK0eAhbG\nqaVb9Gn/q4osAzbYrK4qnwN2z6nEd1xss3sWoOfBcd6l3wnqaA0lpheSkvstPOk9\nwUlv8OswMKad2Nkm+bJlsjie+9zJrj1wjZqgvlAw+UBVpvGJIcpDcrzU5bcHe38C\nsB054Fhhq4eHqTnyKV1TieMwxxzGCMX2eWUc4jGZD8qRsVxMpVFyCoq5lrFOO0ju\nPlL9GbshE6WR2DrkJtiLXXCx0rTdOO/I+O7K7cGOaQKBgQDUh1YEDIRhQCGpOfOk\n+5xCTTCYyuDkvRttzaqPoExqj+Wp3+RSEhsU/QXUWRem3B5dczYNsXwz8cfHMSuZ\naJb36Ptn1RUOTM/mtpf2nqMTm0cWDPbkZyWbIIlu7aUarOqMBmU6l2NaLUGZng9O\n4WCUkCwJb/5NhlcstbnTqnx/JwKBgQDGWBgxFYXZ6GAFUFigaEU16GWnsrWXf5Wv\n6oDymZewJfJixrfQ9knaATTw6WjNZA/PNNsIQigyFCCqtZ+Nrr6ja5PkajWDmRrL\n94xzSoyyaE1GsDbzJs36YU/5ytRgv/a+L7wmf/Dp6E+CB/fuxhlGyWAotgMKmaVb\nc7Kcx9CRKwKBgEujDr3KJqLlzBUKBuhEk8wU5KK8MWW217gwZe9PwWJ7a+UVRZDf\n2LuTgSuduYks9bGUezxMWkOyXMNLgJTMYE5CoJbHgwLf223kthiTRL6JmLcETinj\ne8LFxaOw9/2SZLcI36YGktlZGeuuEQylsVWuKb4CGaNFmGjVFBl8p2opAoGAIOjZ\nCrvhe6h81iTgr6zdXvZyd4LFcp+uvIOSZa5qZsXFE/KY6UkUM+HjYN25j+q8XMsO\nVK51cOm6Cdkc4itOYlslLM8alf0/XvxyB2clNSztNX9jfrC/mH2xAWdQGcWAMUpG\nU7UCyizLlG2yC6I+62naVKbulkGsGCJ4Tl8xEgUCgYA9bZQJeiUxelGaDy0S6VFQ\n1SDYrgGBzWABhGCkJkF8abf5lPvKLDdJI5p4pxqy5i8gVYu4EfiBb6KGx2TpOaCa\nkRhpR+RBoqanzQwlb8PwczcnhoZMj/YuRvPnZYAP8ZzqHmndl1GWOp1WtBqrRGZ5\nJaKkl3ktYKtTOu2BmGPeuA==\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-euuxj@eurorageux.iam.gserviceaccount.com",
    "client_id": "105340017650714666091",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-euuxj%40eurorageux.iam.gserviceaccount.com",
    "universe_domain": "googleapis.com"
}
const bucket = "gs://eurorageux.appspot.com";
const firebaseApp = initializeApp(firebaseConfig);

export const listFiles = async () => {
    const storage = getStorage(firebaseApp, bucket);
    const listRef = ref(storage);
    listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
            });
            res.items.forEach((itemRef) => {
                console.log(itemRef.name);
                console.log(itemRef.parent);
            });
        }).catch((error) => {
        // Uh-oh, an error occurred!
    });
}

export const downloadUserList = async () => {
    const storage = getStorage(firebaseApp, bucket);
    const storageRef = ref(storage, bucket + '/users.json');

    console.log("DownloadUserList")
    return getDownloadURL(storageRef).then(url => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
                blob.text().then(async (data) => {
                    let json = JSON.parse(data);
                    let users = [];
                    for (const jsonElement of json) {
                        users = [...users, createUserFromDict(jsonElement)]
                    }
                    resolve(users);
                });
            };
            xhr.onerror = () => {
                reject("Request failed");
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(error => {
            console.log("error getDownloadURL " + error)
        });
    });
}


export const uploadSingleUser = async (cUser: User, setUsers) => {
    downloadUserList().then((oldUserList) => {
        let updatedUserList = oldUserList.map(user => user.id === cUser.id ? cUser : user);
        uploadUsers(updatedUserList).then(() => {
            setUsers(updatedUserList);
        });
    });
}


export const uploadSingleUserGame = async (cUser: User, cGameId: number, setUsers, setCUser) => {
    downloadUserList().then((oldUserList) => {
        let oldCUser: User = oldUserList.find(user => user.id === cUser.id);
        const cBet = cUser.matchHistory.find(bet => bet[0] === cGameId);
        let oldBet = oldCUser.matchHistory.find(game => game[0] === cGameId);
        if (oldBet !== undefined) {
            let index = oldCUser.matchHistory.indexOf(oldBet);
            oldCUser.matchHistory[index] = cBet;
        } else {
            oldCUser.matchHistory.push(cBet);
        }

        const cQBet = cUser.qualifyHistory.find(bet => bet[0] === cGameId);
        let oldQBet = oldCUser.qualifyHistory.find(game => game[0] === cGameId);
        if (oldQBet !== undefined) {
            let index = oldCUser.qualifyHistory.indexOf(oldQBet);
            oldCUser.qualifyHistory[index] = cQBet;
        } else {
            oldCUser.qualifyHistory.push(cQBet);
        }

        let updatedUserList = oldUserList.map(user => user.id === cUser.id ? oldCUser : user);
        uploadUsers(updatedUserList).then(() => {
            setUsers(updatedUserList);
            setCUser(oldCUser);
        });
    });
}


export const addUser = async (cUser: User, setUsers) => {
    downloadUserList().then((oldUserList) => {
        let updatedUserList = [...oldUserList, cUser];
        setUsers(updatedUserList);
        uploadUsers(updatedUserList).then(() => {
            console.log("User added")

        });
    });
}


export const uploadUsers = async (userList: [User]) => {
    const storage = getStorage(firebaseApp, bucket);
    const storageRef = ref(storage, bucket + '/users.json');

    const users = JSON.stringify(userList);
    const blob = new Blob([users], {type: "application/json"});
    await uploadBytes(storageRef, blob).then(() => {
        console.log("Users uploaded")
    });
}

export const downloadGamesList = async () => {
    const storage = getStorage(firebaseApp, bucket);
    const storageRef = ref(storage, bucket + '/games.json');

    console.log("DownloadGamesList")
    return getDownloadURL(storageRef).then(url => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
                blob.text().then(async (data) => {
                    let json = JSON.parse(data);
                    let games = [];
                    for (const jsonElement of json) {
                        let cGame = createGameFromDict(jsonElement)
                        // console.log("In game download: " + cGame.id + " " + cGame.teamA.name + " vs " + cGame.teamB.name)
                        games = [...games, cGame]
                    }
                    resolve(games);
                });
            };
            xhr.onerror = () => {
                reject("Request failed");
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(error => {
            console.log("error getDownloadURL " + error)
        });
    });
}


export const uploadGames = async (gameList: [Game]) => {
    if (gameList.length === 0) {
        alert("The list of game is empty, stp envoie moi un screen de l'erreur comme ca je debug")
        return;
    }
    const storage = getStorage(firebaseApp, bucket);
    const storageRef = ref(storage, bucket + '/games.json');

    const games = JSON.stringify(gameList);
    const blob = new Blob([games], {type: "application/json"});
    await uploadBytes(storageRef, blob).then(() => {
        console.log("Games uploaded")
    });
}


export const uploadSingleGame = async (cGame: Game, setGames) => {

    downloadGamesList().then((oldUserList) => {
        let updatedGameList = oldUserList.map(game => game.id === cGame.id ? cGame : game);
        uploadGames(updatedGameList).then(() => {
            console.log("Game " + cGame.teamA.name + " vs " + cGame.teamB.name + " uploaded successfully");
            setGames(updatedGameList);
        });
    });
}


export const downloadSpecialBetList = async () => {
    const storage = getStorage(firebaseApp, bucket);
    const storageRef = ref(storage, bucket + '/specialBet.json');

    console.log("downloadSpecialBetList")
    return getDownloadURL(storageRef).then(url => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
                blob.text().then(async (data) => {
                    let json = JSON.parse(data);
                    let bets = [];
                    for (const jsonElement of json) {
                        let cBet = createSpecialBetFromDic(jsonElement)
                        bets = [...bets, cBet]
                    }
                    resolve(bets);
                });
            };
            xhr.onerror = () => {
                reject("Request failed");
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(error => {
            console.log("error getDownloadURL " + error)
        });
    });
}


export const uploadSpecialBets = async (betList: [SpecialBets]) => {
    const storage = getStorage(firebaseApp, bucket);
    const storageRef = ref(storage, bucket + '/specialBet.json');

    const bets = JSON.stringify(betList);
    const blob = new Blob([bets], {type: "application/json"});
    await uploadBytes(storageRef, blob).then(() => {
        console.log("Special bets uploaded")
    });
}


