import styles from "../gameOverview/gameOverview.module.css";
import React, {useState} from "react";
import {uploadSingleGame} from "../../utilFiles/fireBaseStorage";
import type Game from "../../dataType/game";


interface ScoreManagerProps {
    game: Game;
    setGames: (games: [Game]) => void;
}


const ScoreManagerQuali: React.FC<ScoreManagerProps> = ({game, setGames}) => {
    const [score: [number, number], setScore] = useState([0, 0]);
    const [drawAt90, setDrawAt90] = useState(game.drawAt90 || false);
    const [qualified, setQualified] = useState(null);

    const handleButtonClick = (value) => {
        setQualified(value);
    };

    const handleDrawAt90Click = () => {
        setDrawAt90(!drawAt90);
    };

    const handleScoreSubmit = (event) => {
        event.preventDefault();
        // Here you can handle the score submission logic
        console.log(`Score for Team A: ${score[0]}, Score for Team B: ${score[1]}`);
        if (game.score) {
            game.score = [Number(score[0]), Number(score[1])];
        } else {
            game.score[0] = Number(score[0]);
            game.score[1] = Number(score[1]);
        }
        if (score[0] > score[1]) {
            game.result = "1";
            game.qResult = "1";
        } else if (score[0] < score[1]) {
            game.result = "2";
            game.qResult = "2";
        } else {
            game.result = "X";
        }
        game.drawAt90 = drawAt90;
        if (drawAt90) {
            game.result = "X";
            if (score[0] === score[1]){
                if (qualified){
                    game.qResult = qualified;
                }else {
                    alert("Tu dois sélectionner le pays qualifié");
                    return;
                }
            }
        }
        uploadSingleGame(game, setGames).then();
    };

    return (
        <form onSubmit={handleScoreSubmit} className={styles.verticalFlex}>
            <ul className={styles.horizontalFlex}>
                <label>
                    A:
                    <input type="number" className={styles.inputShort} value={score[0]}
                           onChange={(e) => setScore([Number(e.target.value), score[1]])}/>
                </label>
                <label>
                    B:
                    <input type="number" className={styles.inputShort} value={score[1]}
                           onChange={(e) => setScore([score[0], Number(e.target.value)])}/>
                </label>
            </ul>
            <label>
                Nul à 90':
                <input type="checkbox" checked={drawAt90} onChange={handleDrawAt90Click}/>
            </label>
            {(drawAt90 && score[0] === score[1]) && (
                <div className={styles.verticalFlex}>
                    <label>
                        Qualifié final
                    </label>
                    <div className={styles.horizontalFlex}>
                        {game.teamA.name}
                        <input type="checkbox" checked={qualified === "1"} onChange={() => handleButtonClick("1")}/>
                        {game.teamB.name}
                        <input type="checkbox" checked={qualified === "2"} onChange={() => handleButtonClick("2")}/>
                    </div>

                </div>
            )}
            <input type="submit" value="Validate"/>
        </form>);
}

export default ScoreManagerQuali;
